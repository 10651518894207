import React, {useRef, useState, useContext} from "react";
import {
    Text,
    PageSection,
    Pagination,
    PaginationVariant,
    DropdownDirection
} from "@patternfly/react-core";
import {SortByDirection} from "@patternfly/react-table";
import {useHistory} from "react-router-dom";

import PageWrapper from "../common/page/PageWrapper";
import EmptyStateSpinner from "../common/screenStates/EmptyStateSpinner";
import NoMatchEmptyState from "../common/screenStates/NoMatchEmptyState";
import ErrorState from "../common/screenStates/ErrorState";
import MemberSchemesFilterPannel, {MemberSchemesFilterPannelRef} from "../common/components/schemes/MemberSchemesFilterPannel";

import {fetchMemberSchemesHook} from "../common/hooks/fetchMemberSchemesHook";
import {memberSchemesColumsDefs} from "../utils/constants";
import {buildQueryFromState} from "../utils/utils";
import {fetchClientNamesHook} from "../common/hooks/fetchClientsHook";
import {fetchSchemeNamesHook} from "../common/hooks/fetchSchemesHook";
import MemberSchemesExportButton from "../common/components/schemes/MemberSchemesExportComponent";
import * as actions from "../reducers/actions/memberSchemesActions";
import {MemberSchemesState} from "../reducers/memberSchemesReducer";
import SortableMemberSchemesTable from "../common/components/schemes/SortableMemberSchemesTable";
import {suspendMemberScheme, resumeMemberScheme, deleteMemberScheme} from "../services/membersSchemeService";
import ConfirmDeleteModal from "../common/components/ConfirmDeleteModal";
import {SharedContext} from "../App";


export interface ManageMembersPageProps {
    dispatch: (action: actions.MemberSchemesActions) => void;
    state: MemberSchemesState;
}

const ManageMembersPage: React.FC<ManageMembersPageProps> = ({dispatch, state}) => {
    const {addSuccessToast, addErrorToast} = useContext(SharedContext);

    const [memberSchemes, totalCount, loading, done] = fetchMemberSchemesHook(buildQueryFromState(state));
    const [clientNames] = fetchClientNamesHook();
    const [schemeNames] = fetchSchemeNamesHook();
    const history = useHistory();

    const [confirmDelete, setConfirmDelete] = useState<{ id: number } | null>(null);

    const filterPannel = useRef<MemberSchemesFilterPannelRef>(null);

    const onSorted = (index: number, direction: SortByDirection) => dispatch(
        actions.setSortByAction({
            index,
            key: memberSchemesColumsDefs[index].sortKey,
            direction
        })
    );


    const onPageSizeChanged = (event, pageSize) => {
        dispatch(actions.setPageAction(0));
        dispatch(actions.setPageSizeAction(pageSize));
    };

    // page in API is indexed from 0
    const onPageChanged = (event, page) => dispatch(actions.setPageAction(page));

    // bypass caching the search by adding timestamp so user can repeat the search
    const onSearch = (filtersQuery: string) => dispatch(
        actions.setSchemesFiltersQueryAction(filtersQuery + (filtersQuery && "&") + `t=${Date.now()}`)
    );

    const reload = () => {
        if (filterPannel.current){
            const query = filterPannel.current.getQuery();
            dispatch(
                actions.schemesReloadAction(query + (query && "&") + `t=${Date.now()}`)
            );
        }
    };

    const clearFilters = () => {
        if (filterPannel && filterPannel.current) {
            filterPannel.current.reset();

            // delay execution so state would be already updated
            // damn React does not provide any hint when state update is done in FC!
            window.setTimeout(() => {
                // prevent crash when component would be already unloaded
                filterPannel && filterPannel.current && filterPannel.current.search();
            }, 100);
        }
    };

    const suspendScheme = (id: number) => {
        suspendMemberScheme(id.toString(), history).then(json => {

            if (json.success) {
                addSuccessToast({
                    title: "Scheme suspending success", message: `Scheme ID ${id} was suspended.`
                });
            } else {
                addErrorToast({
                    title: "Scheme suspending failed", message: `Can't suspend scheme ID ${id}.`
                });
            }

        }).catch(error => {
            addErrorToast({
                title: "Scheme suspending failed", message: error.toString()
            });

        }).finally(reload);
    };

    const resumeScheme = (id: number) => {
        resumeMemberScheme(id.toString(), history).then(json => {

            if (json.success) {
                addSuccessToast({
                    title: "Scheme resuming success", message: `Scheme ID ${id} was resumed.`
                });
            } else {
                addErrorToast({
                    title: "Scheme resuming failed", message: `Can't resume scheme ID ${id}.`
                });
            }

        }).catch(error => {
            addErrorToast({
                title: "Scheme resuming failed", message: error.toString()
            });

        }).finally(reload);
    };

    const openConfirmDeleteDialog = (id: number) => setConfirmDelete({id});

    const hideConfirmDeleteDialog = () => setConfirmDelete(null);

    const removeScheme = (id: number) => {
        hideConfirmDeleteDialog();

        deleteMemberScheme(id.toString(), history).then(json => {

            if (json.success) {
                addSuccessToast({
                    title: "Scheme deletion success", message: `Scheme ID ${id} was removed.`
                });
            } else {
                addErrorToast({
                    title: "User deletion failed", message: `Can't remove scheme ID ${id}.`
                });
            }

        }).catch(error => {
            addErrorToast({
                title: "User deletion failed", message: error.toString()
            });

        }).finally(reload);
    };

    const noResults = memberSchemes ? memberSchemes.length === 0 : true;

    return (
        <PageWrapper
            id="member-manager"
            breadcrumbItems={[{
                label: "Manage Members",
                subpath: "member-manager"
            }]}
        >
            <PageSection className="tp--page__header">
                <Text component="h1" >Manage Member Schemes</Text>
                <MemberSchemesFilterPannel
                    ref={filterPannel}
                    query={state.filtersQuery}
                    schemeOptions={schemeNames}
                    clientOptions={clientNames}
                    onSearch={onSearch}
                    onFilterChange={() => dispatch(actions.schemesFilterChangedAction())}
                />
            </PageSection>
            <PageSection isFilled>
                <div className="tp--page__background">
                    <div className="tp--page__table-wrapper">
                        <header className="tp--page__table-header">
                            <Text component="h2">
                                {`Filtered Results (${totalCount})`}
                            </Text>
                            <MemberSchemesExportButton
                                query={state.filtersQuery}
                                disabled={noResults || state.filterChangedSinceSearch}
                            />
                        </header>
                        {loading && !done && <EmptyStateSpinner/>}
                        {!loading && !done && <ErrorState errorText="Something went wrong with the network. Please retry."/>}
                        {!loading && done && noResults && <NoMatchEmptyState onClearAllFilters={clearFilters}/>}
                        {done && !noResults && (
                            <SortableMemberSchemesTable
                                columnsDefs={memberSchemesColumsDefs}
                                data={memberSchemes}
                                sortBy={state.sortBy}
                                onSorted={onSorted}
                                onSuspend={suspendScheme}
                                onResume={resumeScheme}
                                onDelete={openConfirmDeleteDialog}
                            />
                        )}
                    </div>
                    <Pagination
                        className="tp--table--pagination"
                        itemCount={totalCount}
                        perPage={state.pageSize}
                        page={state.page}
                        variant={PaginationVariant.bottom}
                        dropDirection={DropdownDirection.up}
                        onPerPageSelect={onPageSizeChanged}
                        onSetPage={onPageChanged}
                    />
                </div>
                {
                    confirmDelete !== null &&
                    <ConfirmDeleteModal
                        title="Delete Member Scheme"
                        onConfirm={() => removeScheme(confirmDelete.id)}
                        onCancel={hideConfirmDeleteDialog}
                    >
                        <p>
                            Please confirm that you&apos;d like to delete member scheme ID <span className="tp--user-name">{confirmDelete.id}</span>.
                        </p>
                    </ConfirmDeleteModal>
                }
            </PageSection>
        </PageWrapper>
    );
};

export default ManageMembersPage;
