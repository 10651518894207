import {History} from "history";

import {API_BASE_URL} from "../utils/constants";
import {ApiJsonResponse, callSecuredApiEndpoint} from "./apiCalls";

export type MemberSchemeData = {
    id: number;
    urn: string | null;
    name: string | null;
    schemeName: string | null;
    clientName: string | null;
    memberEmail: string | null;
    memberId: string | null;
    nationalInsuranceNumber: string | null;
    securityCode: string | null;
    sex: string | null;
    verified: boolean;
    suspended: boolean | null;
}

export type MeberSchemesMetadata = {
    totalPages: number;
    totalElements: number;
    last: boolean;
    number: number; // page index
    sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
    size: number;
    first: boolean;
    numberOfElements: number;
    empty: false;
    pageable: {
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
}

export interface MembersRequestJson extends ApiJsonResponse, MeberSchemesMetadata {
    content: MemberSchemeData[];
}

/**
 * Definition of fetch call for members endpoint
 */
const requestMembers = (query: string) => (authorization: string) =>
    fetch(
        `${API_BASE_URL}member-schemes?${query}`,
        {
            headers: new window.Headers({
                Authorization: authorization
            }),
            method: "GET"
        }
    ).then(response => response.json()) as Promise<MembersRequestJson>;


export const getMembers = (query: string, history: History) => callSecuredApiEndpoint(requestMembers(query), history);

export interface TpClientData {
    id: string;
    name: string;
}

export interface ClientsRequestJson extends ApiJsonResponse {
    clients: TpClientData[];
}

const requestClients = (authorization: string) => fetch(
    `${API_BASE_URL}clients`,
    {
        headers: new window.Headers({
            Authorization: authorization
        }),
        method: "GET"
    }
).then(response => response.json()) as Promise<ClientsRequestJson>;


export const getClients = (history: History) => callSecuredApiEndpoint(requestClients, history);


export interface TpSchemeData {
    id: string;
    name: string;
}

export interface SchemesRequestJson extends ApiJsonResponse {
    schemes: TpSchemeData[];
}


const requestSchemes = (authorization: string) => fetch(
    `${API_BASE_URL}schemes`,
    {
        headers: new window.Headers({
            Authorization: authorization
        }),
        method: "GET"
    }
).then(response => response.json()) as Promise<SchemesRequestJson>;

export const getSchemes = (history: History) => callSecuredApiEndpoint(requestSchemes, history);

const requestExport = (query: string) => (authorization: string) =>
    fetch(
        `${API_BASE_URL}member-schemes${query ? "?" : ""}${query}`,
        {
            headers: new window.Headers({
                Authorization: authorization,
                "Content-Type": "text/csv"
            }),
            method: "GET"
        }
    ).then(response => response.blob()) as Promise<any>;


export const getExportCsv = (query: string, history: History) => callSecuredApiEndpoint(requestExport(query), history);

export interface SchemeActionResponse extends ApiJsonResponse {
    success: boolean;
}

const suspendMemberSchemeRequest = (schemeId: string) => (authorization: string) => fetch(
    `${API_BASE_URL}member-schemes/${schemeId}/suspend`,
    {
        headers: new window.Headers({
            Authorization: authorization
        }),
        method: "PUT"
    }
).then(response => ({
    success: response.status === 204
})) as Promise<SchemeActionResponse>;

export const suspendMemberScheme = (id: string, history: History) => callSecuredApiEndpoint(suspendMemberSchemeRequest(id), history);

const resumeMemberSchemeRequest = (schemeId: string) => (authorization: string) => fetch(
    `${API_BASE_URL}member-schemes/${schemeId}/resume`,
    {
        headers: new window.Headers({
            Authorization: authorization
        }),
        method: "PUT"
    }
).then(response => ({
    success: response.status === 204
})) as Promise<SchemeActionResponse>;

export const resumeMemberScheme = (id: string, history: History) => callSecuredApiEndpoint(resumeMemberSchemeRequest(id), history);

const deleteMemberSchemeRequest = (schemeId: string) => (authorization: string) => fetch(
    `${API_BASE_URL}member-schemes/${schemeId}`,
    {
        headers: new window.Headers({
            Authorization: authorization
        }),
        method: "DELETE"
    }
).then(response => ({
    success: response.status === 204
})) as Promise<SchemeActionResponse>;

export const deleteMemberScheme = (id: string, history: History) => callSecuredApiEndpoint(deleteMemberSchemeRequest(id), history);
