import React, {useState, useEffect} from "react";
import {
    Table,
    TableHeader,
    TableBody,
    sortable,
    ISortBy,
    ICell,
    IRow,
    TableGridBreakpoint,
    IAction,
    TableVariant,
    IRowData,
    RowWrapper,
    RowWrapperProps
} from "@patternfly/react-table";
import {useHistory} from "react-router-dom";


import {MemberScheme, ColumnDef, SortByDirection} from "../../../utils/types";
import DeclineIcon from "../../../icons/DeclinedIcon";
import CheckIcon from "../../../icons/CheckIcon";
import {Routes} from "../../../utils/constants";


type RowCell = {
    title: React.ReactNode;
    value: string | boolean | number;
}

type Row = IRow & {
    uid: number;
    suspended: boolean;
    cells: RowCell[];
};

export type SchemeActionCallback = (uid: number) => void;

export interface SortableMemberSchemesTableProps {
    columnsDefs: ColumnDef[];
    data: MemberScheme[];
    sortBy: ISortBy;
    onSorted: (index: number, direction: SortByDirection) => void;
    onSuspend: SchemeActionCallback;
    onResume: SchemeActionCallback;
    onDelete: SchemeActionCallback;
}


const SortableMemberSchemesTable: React.FC<SortableMemberSchemesTableProps> = ({
    data = [],
    columnsDefs,
    sortBy,
    onSorted,
    onSuspend,
    onResume,
    onDelete
}) => {

    const history = useHistory();
    const [rows, setRows] = useState<Row[]>([]);
    const rowKeys = columnsDefs.map(def => def.key);

    useEffect(() => {
        setRows(data.map(member => {
            const row: Row = {
                uid: member.id,
                suspended: member.suspended,
                cells: []
            };

            rowKeys.forEach(key => {
                const value = member[key];
                const rowCell: RowCell = {
                    title: value,
                    value
                };

                if (typeof value === "boolean") {
                    rowCell.title = value ? <CheckIcon fill="#92D400" title="Verified"/> : <DeclineIcon fill="#E14C4C" title="Unverified"/>;
                }
                row.cells.push(rowCell);

            });
            return row;
        }));
    }, [data]);

    const columns: ICell[] = columnsDefs.map(def => ({
        title: def.title,
        transforms: def.sortable ? [sortable] : []
    }));

    const viewDetailAction: IAction = {
        title: "Show detail",
        target: "_blank",
        onClick: (e, rowIndex, rowData) => history.push(Routes.MemberSchemesDetail + rowData.uid)
    };

    const suspendAction: IAction = {
        title: "Suspend Member Scheme",
        onClick: (e, rowIndex, rowData) => onSuspend(rowData.uid)
    };

    const resumeAction: IAction = {
        title: "Resume Member Scheme",
        onClick: (e, rowIndex, rowData) => onResume(rowData.uid)
    };

    const deleteAction: IAction = {
        title: "Delete Member Scheme",
        onClick: (e, rowIndex, rowData) => onDelete(rowData.uid)
    };


    const actionResolver = (rowData: IRowData) => {
        const row = rowData as Row;

        const actions: IAction[] = [
            viewDetailAction,
            (row.suspended ? resumeAction : suspendAction),
            deleteAction
        ];

        return actions;
    };


    const onSort = (_event: any, index: number, direction: SortByDirection) => {
        onSorted(index, direction);
    };

    const rowWrapperResolver = (props: RowWrapperProps) => {
        const row = props.row as Row;
        const className = row.suspended ? "tp--suspended" : "";

        return (<RowWrapper {...props} className={className}/>);
    };


    return (
        <Table
            aria-label="Members Table"
            variant={TableVariant.compact}
            sortBy={sortBy}
            onSort={onSort}
            cells={columns}
            rows={rows}
            gridBreakPoint={TableGridBreakpoint.gridXl}
            actionResolver={actionResolver}
            rowWrapper={rowWrapperResolver}
        >
            <TableHeader className="tp--table__header"/>
            <TableBody/>
        </Table>
    );
};

export default SortableMemberSchemesTable;
