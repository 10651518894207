import {UserRolesEnum} from "./constants";

export type MemberSchemeGender = "Female" | "Male" | "Other" | "Unknown" | "";

export type MemberScheme = {
    id: number;
    name: string;
    urn: string;
    schemeName: string;
    clientName: string;
    memberId: string;
    nin: string;
    securityCode: string;
    gender: MemberSchemeGender;
    verified: boolean;
    suspended: boolean;
}


export type ColumnDef = {
    title: string;
    key: string;
    type?: "string" | "number" | "boolean";
    sortable: boolean;
    sortKey: string;
}

export enum SortByDirection {
    asc = "asc",
    desc = "desc"
}

export interface SortBy {
    index: number;
    key: string;
    direction: SortByDirection;
}

export type SchemeProfileAddress = {
    line1: string;
    line2: string;
    townCity: string;
    country: string;
    postcode: string;
    countyOrProvince: string;
};

export type SchemeProfileSpouseDetail = {
    forename: string;
    middleName: string;
    surname: string;
    sex: string;
    dateOfBirth: string;
};

export type SchemeProfileBankDetail = {
    accountNumber: string;
    sortCode: string;
    accountName: string;
};

export type MemberSchemeMaritalStatus = "Married" | "Cohabiting" | "Single" | "Widow/Widower" | "Prefer not to say" | "Unknown" | "";

export type SchemeProfileRaw = Partial<{
    title: string;
    forename: string;
    middleName: string;
    surname: string;
    sex: string;
    dateOfBirth: string;
    address: Partial<SchemeProfileAddress>;
    spouseDetail: Partial<SchemeProfileSpouseDetail>;
    bankDetail: Partial<SchemeProfileBankDetail>;
    maritalStatus: string;
    email: string;
    telephone: string;
    lastUpdated: string;
}>;


export type SchemeProfile = {
    title: string;
    forename: string;
    middleName: string;
    surname: string;
    sex: string;
    dateOfBirth: string;
    address: SchemeProfileAddress;
    spouseDetail: SchemeProfileSpouseDetail;
    bankDetail: SchemeProfileBankDetail;
    maritalStatus: MemberSchemeMaritalStatus;
    email: string;
    telephone: string;
    lastUpdated: string;
};

export type ProjectClient = {
    id: number;
    name: string;
    number: any;
    contactName: string;
    contactEmail: string;
}

export type Project = {
    number: string;
    client: ProjectClient;
}


export type MemberSchemeDetail = {
    uuid: string;
    schemeName: string;
    urn: string;
    securityCode: string;
    clientReferenceNumber: string;
    nationalInsuranceNumber: string;
    originalProfile: SchemeProfile | null;
    updatedProfile: SchemeProfile | null;
    project: Project;
}

export type UserRaw = {
    id: number;
    email: string;
    fullname: string;
    active: boolean;
    roles: UserRolesEnum[];
}

export type UserRoleName = "Operator" | "Admin";

export type User = {
    id: number;
    email: string;
    name: string;
    active: boolean;
    role: UserRoleName | "";
}

export type EditUserData = {
    email: string;
    name: string;
    active: boolean;
    role: UserRoleName;
}

export type ChangePasswordPayload = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export type ResetPasswordPayload = {
    email: string;
}
